import { RadioGroup } from "components/common/forms";
import { Answer } from "types/answer";
import { IMultipleChoiceOption } from "types/input.type";
import { OptionQuestion } from "types/question";
import { User } from "types/user";
import { UserUtil } from "utils";
import UserProfileCard from "./UserProfileCard";

type MultipleChoiceFeedbackProps = {
  question: OptionQuestion;
  answer: Answer;
  reviewer?: User;
  showUserProfileCard?: boolean;
};

const MultipleChoiceFeedback: React.FC<MultipleChoiceFeedbackProps> = ({
  question,
  answer,
  reviewer,
  showUserProfileCard = false,
}) => {
  const userProfile = UserUtil.transformToUserProfile(reviewer);
  const options: IMultipleChoiceOption[] = question.answerOptions.map(
    (option) => ({
      label: option.label,
      value: option.id,
    }),
  );

  const radioGroupName = `radio-group-${answer.id}`;

  return (
    <div className="flex flex-col gap-2">
      {showUserProfileCard && <UserProfileCard userProfile={userProfile} />}
      <RadioGroup
        name={radioGroupName}
        selectedValue={answer.answerOptionId}
        options={options}
        id={radioGroupName}
      />
    </div>
  );
};

export default MultipleChoiceFeedback;
